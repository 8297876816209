import "./styles.scss";
import "./flipper.scss";
import anime from "animejs";
import { map, zip, fromEvent, pipe, withLatestFrom } from "./Observable";
import confetti from "canvas-confetti";

window.anime = anime;

var isRotating = false;
const rouletteWheelItems = [
  "$10",
  "$20",
  "10 Birr",
  "$50",
  "$100",
  "Laptop",
  "$5",
  "Tablet",
];

const getRouletteWheelItem = (index) =>
  rouletteWheelItems[index % rouletteWheelItems.length];

function addFlipper() {
  const mkDiv = (className) => {
    const d = document.createElement("div");
    d.classList.add(...className.split(" "));
    return d;
  };
  const flipper = mkDiv("flipper");
  const front = mkDiv("front-face");
  const back = mkDiv("back-face");

  flipper.appendChild(front);
  flipper.appendChild(back);
  // document.querySelector(".result").appendChild(flipper);
  return (item) => {
    flipper.classList.add("flip");
    back.innerText = item;

    // Add claim button inside the back face
    const claimButton = document.createElement("button");
    claimButton.classList.add("btn", "btn-primary");
    claimButton.innerText = "Claim";
    claimButton.style.backgroundColor = "#dca041";
    claimButton.style.display = "block";
    claimButton.addEventListener("click", () => {
      $("#claimModal").modal("show");
    });
    back.appendChild(claimButton);
  };
}

function startRotation(speed) {
  if (isRotating) {
    return;
  }

  isRotating = true;

  const writeResult = addFlipper();

  const bezier = [0.165, 0.84, 0.44, 1.005];

  // Calculate the new rotation to land on the specified position
  const targetIndex = rouletteWheelItems.indexOf("$100");
  const degreesPerSegment = 360 / rouletteWheelItems.length;
  const targetRotation = 364 * 6 + targetIndex * degreesPerSegment;

  // Add the blinking class to the wheel
  const wheelElement = document.querySelector(".roulette-wheel");
  wheelElement.classList.add("blinking");

  // Wheel rotation animation
  anime({
    targets: [".layer-2", ".layer-4"],
    rotate: targetRotation,
    duration: 5000,
    easing: `cubicBezier(${bezier.join(",")})`,
    complete: () => {
      writeResult("10 Birr");
      isRotating = false;

      // Remove the blinking class after the spin completes
      wheelElement.classList.remove("blinking");

      // Reset the rotation after the spin completes for subsequent spins
      document.querySelector(".layer-2").style.transform = `rotate(${
        targetRotation % 360
      }deg)`;
      document.querySelector(".layer-4").style.transform = `rotate(${
        targetRotation % 360
      }deg)`;

      // Trigger confetti animation
      confetti({
        particleCount: 1000,
        spread: 140,
        origin: { y: 1 },
        style: {
          // Add a new style property here
          zIndex: 300, // Set the z-index to 300
        },
      });

      // Show congratulatory message
      // const congratsOverlay = document.getElementById("congratsOverlay");
      // const congratsMessage = document.getElementById("congratsMessage");
      // congratsMessage.innerText = `Congratulations! You won 10 Birr`;
      // congratsOverlay.style.display = "flex";

      // Open the claim modal after showing the congratulatory message

      setTimeout(function () {
        $("#claimModal").modal("show");
      }, 1000);

      // setTimeout(() => {
      //   congratsOverlay.style.display = "none";
      // }, 3000);
    },
  });
}

document.addEventListener("DOMContentLoaded", function () {
  document
    .getElementById("claimForm")
    .addEventListener("submit", function (event) {
      event.preventDefault();

      const payload = {
        first_name: document.getElementById("first_name").value,
        // last_name: document.getElementById("last_name").value,
        // password: document.getElementById("password").value,
        username: document.getElementById("username").value,
      };

      // Replace with your API endpoint
      fetch("https://api.habtam.bet/phone-register/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          console.log(data.unique_link);
          console.log(data.id);
          if (
            data.username &&
            data.username[0] === "The phone number is already used."
          ) {
            // Show a popup informing the user that the phone number is already used
            alert("You have already taken your gift before.");

            // Refresh the page after 3 seconds
            setTimeout(function () {
              location.reload();
            }, 1000);
          }

          // Assuming data is the object containing the response
          if (data.id) {
            window.location.href = data.unique_link;
            console.log("unique link");
          }

          $("#claimModal").modal("hide");
          document.getElementById("claimButton").style.display = "none"; // Hide the claim button after submission
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    });

  document.querySelector(".roulette-wheel").addEventListener(
    "touchmove",
    (e) => {
      e.preventDefault();
    },
    { passive: false }
  );

  document.getElementById("startButton").addEventListener("click", () => {
    const speed = Math.floor(Math.random() * 50) + 50; // Random speed for demo
    startRotation(speed);
  });
});

window.startRotation = startRotation;
